<template>
  <div class="video-box" @click="videoClick">
    <DPlayer :key="video.url" ref="player" :video="video" :autoplay="true" @playing="onPlayerPlay" @fullscreen="onFullscreen" @fullscreen_cancel="onfullscreen_cancel" @pause="onPlayerPause" @loadeddata="onPlayerLoadeddata" @waiting="onPlayerWaiting" @error="onPlayerError" @timeupdate="onTimeupdate" />
    <!-- 购买弹窗 -->
    <div class="bugPopUps" v-show="isShowbuy">
      <div class="buyPopUps-box">
        <div class="buy-info">
          <p>观看完整版需 {{videoInfo.price}} 金币</p>
          <p>{{contenStr}}</p>
        </div>
        <div class="buy-btn-box">
          <span @click="leftBuying">充值金币</span>
          <span @click="rightBuying">立即购买</span>
        </div>
      </div>
    </div>
    <div class="err-box" v-if="isVideoErr">
      <svg-icon iconClass="err"></svg-icon>
      <p>加载失败</p>
    </div>
    <!-- loading -->
    <van-overlay :show="loading" class="loading flex-center">
      <van-loading type="spinner" color="#f21313" size="24px" vertical>正在支付，请稍等</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import DPlayer from "./VueDplayerHls.vue";
import { mapGetters } from "vuex";
import { sendMediaBuy } from "@/api/index.js";
import { pingVideo } from "@/api/index.js";
import { optRecord } from "@/utils/optLog.js";
export default {
  props: {
    videoInfo: {
      require: true,
    },
  },
  components: {
    DPlayer,
  },
  ...mapGetters({
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  data() {
    return {
      video: {
        url: "",
        type: "hls",
      },
      mediaPath: "media/m3u8/",
      mvPlayer: null, //播放器实例
      videoPlayStatus: false, //播放状态
      isShowbuy: false, //是否显示购买弹窗
      isfullscreen: false, //是否是全屏状态
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      contenStr: "", //会员价文本
      isVideoErr: false, //视频加载失败
      loading: false,
      firstAddLog: true
    };
  },
  mounted() {
    this.initVideo();
    this.mvPlayer = this.$refs.player.dplayer || null;
    // this.mvPlayer.play();
    this.pingVideo();
  },
  methods: {
    initVideo() {
      this.video.url = this.getPath(this.videoInfo.sourceURL);
    },
    // 检查视频是否错误
    async pingVideo() {
      let ret = await this.$Api(pingVideo, this.video.url);
      if (ret.code) {
        this.videoLoading(false);
        this.isVideoErr = true;
      }
    },
    //获取地址
    getPath(path) {
      if (!path) return "";
      let token = localStorage.getItem("Authorization");
      let baseUrl = sessionStorage.getItem("baseUrl");
      let url = `${baseUrl}${this.mediaPath}${path}?Authorization=${token}&c=${this.videoRoadLine.url}`;
      this.video.url = url;
      return url;
    },
    // 监听进入全屏
    onFullscreen() {
      this.isfullscreen = true;
      // 如果没有播放权限禁止进入全屏
      if (this.playCheck() !== true) {
        this.mvPlayer.fullScreen.toggle("browser");
      }
    },
    // 监听退出全屏
    onfullscreen_cancel() {
      this.isfullscreen = false;
    },
    // 视频回调
    onTimeupdate() {
      if (this.playCheck() !== true && this.mvPlayer.video.readyState == 4) {
        this.mvPlayer.pause(); //暂停视频
        if (this.firstAddLog) {
          this.firstAddLog = false;
          optRecord(1, this.videoInfo)
        }
        this.showVideoDialog(); //显示视频购买弹窗
        //如果是全屏状态，退出全屏
        if (this.isfullscreen) {
          this.mvPlayer.fullScreen.toggle("browser");
        }
        return;
      }
      if (this.firstAddLog && this.videoInfo) {
        if (this.videoInfo.playTime > 300) {
          if (this.mvPlayer.video.currentTime > this.videoInfo.playTime * 0.1) {
            this.firstAddLog = false;
            optRecord(1, this.videoInfo);
          }
        } else if (this.mvPlayer.video.currentTime > 15 ) {
          this.firstAddLog = false;
          optRecord(1, this.videoInfo);
        }
      }
    },
    /// 监听当前视频数据加载失败
    onPlayerError() {
      console.log("视频加载失败");
      this.isVideoErr = true;
    },
    /// 监听视频播放的回调函数
    onPlayerPlay() {
      this.videoPlayStatus = true;
      //视频播放隐藏暂停按钮
      this.videoPlayIcon(false);
      this.videoLoading(false);
    },
    /// 监听暂停播放的回调函数
    onPlayerPause() {
      this.videoPlayStatus = false;
      //视频暂停显示按钮
      this.videoPlayIcon(true);
      this.videoLoading(false);
    },

    /// 监听当前视频数据加载完成
    onPlayerLoadeddata() {
      // 视频加载完成，给mvPlayer赋值
      this.mvPlayer = this.$refs.player.dplayer || null;
      // 当自动播放不起作用时，数据加载完成时，执行一遍播放函数
      this.mvPlayer.play();
    },

    /// 监听当前视频数据加载ing
    onPlayerWaiting() {
      //视频数据正在加载打开loading
      this.videoLoading(true);
      //隐藏暂停按钮
      this.videoPlayIcon(false);
    },
    //点击视频
    videoClick() {
      //播放转暂停
      if (this.videoPlayStatus && this.mvPlayer.video.readyState == 4) {
        this.mvPlayer.pause();
      } else {
        //暂停转播放
        if (this.playCheck() !== true && this.mvPlayer.video.readyState == 4) {
          this.mvPlayer.pause();
          this.showVideoDialog();
          //如果是全屏状态，退出全屏
          if (this.isfullscreen) {
            this.mvPlayer.fullScreen.toggle("browser");
          }
        } else {
          this.isShowbuy = false;
          this.mvPlayer.play();
        }
      }
    },
    // 显示视频购买弹窗
    showVideoDialog() {
      this.isShowbuy = true;
      let isVip = this.userInfo.vipType != 0;
      if (isVip) {
        this.contenStr = `我的会员价 ${this.videoInfo.vipPrice} 金币`;
      } else {
        this.contenStr = `会员最低只需 ${this.videoInfo.vipPrice} 金币`;
      }
    },
    //检测视频是否能播放
    playCheck() {
      if (
        this.mvPlayer.video.currentTime < 60 ||
        this.videoInfo.isBuy === true
      ) {
        return true;
      }

      return false;
    },
    // 播放按钮
    videoPlayIcon(isPause) {
      var dplayerBezelIcon =
        document.getElementsByClassName("dplayer-bezel-icon")[0];
      if (isPause) {
        dplayerBezelIcon.style.display = "block";
      } else {
        dplayerBezelIcon.style.display = "none";
      }
    },
    // 视频loading
    videoLoading(isLoading) {
      //视频加载成功隐藏loading
      var dplayerLoading = document.getElementsByClassName(
        "diplayer-loading-icon"
      )[0];
      if (isLoading) {
        dplayerLoading.style.display = "block";
      } else {
        dplayerLoading.style.display = "none";
      }
    },
    // 左边按钮
    leftBuying() {
      let vipGrap = {
        type: "video",
        vInfo: this.videoInfo,
      }
      sessionStorage.setItem('vipGrap', JSON.stringify(vipGrap));
      this.$router.push("/wallet");
    },
    // 右边按钮
    async rightBuying() {
      this.loading = true;
      let ret = await this.$Api(sendMediaBuy, {
        mediaId: Number(this.videoInfo.avId),
        type: "avcomment",
      });
      this.loading = false;
      if (ret.code == 6010) {
        this.$toast(ret.tip || "用户余额不足");
        let vipGrap = {
          type: "video",
          vInfo: this.videoInfo,
        }
        sessionStorage.setItem('vipGrap', JSON.stringify(vipGrap));
        this.$router.push("/wallet");
        return;
      }
      if (ret.code == 200) {
        this.videoInfo.isBuy = true;
        this.$toast("购买成功");
        this.isShowbuy = false;
        this.mvPlayer.play();
        return;
      }
      this.$toast(ret.tip || "购买失败");
    },
  },
};
</script>
<style lang="scss" scoped>
.video-box {
  width: 100%;
  height: 100%;
  background: #000;
}
/deep/ .dplayer-mobile-play {
  display: none !important;
}
.video-box /deep/ .dplayer-bezel-icon {
  width: 40px;
  background: url("../../assets/png/video_pause.png") center center no-repeat;
  background-size: 100%;
  opacity: 1;
  border-radius: 0;
  display: none;
}
.video-box /deep/ .dplayer-menu {
  display: none;
}
.video-box /deep/ .diplayer-loading-icon {
  display: block;
}
.video-box /deep/ .dplayer-full-in-icon {
  display: none !important;
}
.bugPopUps {
  .buyPopUps-box {
    position: absolute;
    border-radius: 6px;
    top: 96px;
    left: 67.5px;
    z-index: 1;
    width: 240px;
    height: 111px;

    .buy-info {
      height: 61px;
      background-image: linear-gradient(to left, $vermillion, #ff7979);
      border-radius: 6px 6px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        padding: 5px 0;
        color: #fff;
        font-size: 14px;
        text-align: center;
        white-space: pre-wrap;
      }
    }
    .buy-btn-box {
      height: 40px;
      background: #fff;
      border-radius: 0 0 6px 6px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        display: inline-block;
        border-radius: 15px;
        padding: 5px 8px;
        font-size: 14px;
      }
      span:nth-child(1) {
        color: $vermillion;
        border: 1px solid $vermillion;
      }
      span:nth-child(2) {
        background: $vermillion;
        color: #fff;
      }
    }
  }
}
.err-box {
  position: absolute;
  top: 110px;
  left: 50%;
  color: #fff;
  margin-left: -51px;
  font-size: 26px;
  text-align: center;
  p {
    text-align: center;
    padding-top: 8px;
  }
}
</style>
