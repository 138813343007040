<template>
  <div class="dplayer">
    <div ref="dplayer" playsinline webkit-playsinline></div>
  </div>
</template>

<script>
export default {
  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value.url === "string";
      },
    },
    theme: {
      type: String,
      default: "#f21313",
    },
    loop: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: "zh-cn",
    },
    hotkey: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
      default: "",
    },
    screenshot: {
      type: Boolean,
      default: false,
    },
    contextmenu: {
      type: Array,
      default: () => [],
    },
    preload: {
      type: String,
      default: "auto",
    },
  },
  data() {
    return {
      dplayer: null,
    };
  },
  watch: {
    video(val) {
      if (this.dplayer) {
        this.dplayer.switchVideo(val);
      }
    },
  },
  mounted() {
    this.initVideo();
    if (this.dplayer.video.paused) {
      this.dplayer.play();
    }
  },
  methods: {
    initVideo() {
      // eslint-disable-next-line no-undef
      const player = (this.dplayer = new DPlayer({
        container: this.$refs.dplayer,
        autoplay: true,
        theme: this.theme,
        loop: this.loop,
        lang: this.lang,
        hotkey: this.hotkey,
        logo: this.logo,
        screenshot: this.screenshot,
        contextmenu: this.contextmenu,
        preload: this.preload,
        video: {
          url: this.video.url,
          pic: this.video.pic,
          type: this.video.type,
        },
      }));
      player.on("play", () => {
        this.$emit("play");
      });
      player.on("playing", () => {
        this.$emit("playing");
      });
      player.on("pause", () => {
        this.$emit("pause");
      });
      player.on("canplay", () => {
        this.$emit("canplay");
      });
      player.on("ended", () => {
        this.$emit("ended");
      });
      player.on("error", () => {
        this.$emit("error");
      });
      player.on("loadeddata", () => {
        this.$emit("loadeddata");
      });
      player.on("timeupdate", () => {
        this.$emit("timeupdate");
      });
      player.on("durationchange", () => {
        this.$emit("durationchange");
      });
      player.on("waiting", () => {
        this.$emit("waiting");
      });
      player.on("fullscreen", () => {
        this.$emit("fullscreen");
      });
      player.on("destroy", () => {
        this.$emit("destroy");
      });
      player.on("fullscreen_cancel", () => {
        this.$emit("fullscreen_cancel");
      });
      player.on("seeking", () => {
        // player.play();
        this.$emit("progress");
      });
    },
  },
  beforeDestroy() {
    if (this.dplayer) {
      this.dplayer.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.dplayer {
  width: 100%;
  height: 100%;
}
/deep/ .dplayer-setting {
  display: none !important;
}
</style>
