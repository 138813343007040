<template>
  <div class="av-box">
    <van-nav-bar title="AV解说" @click-left="onClickLeft" :border="false">
      <template #left>
        <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
      </template>
    </van-nav-bar>
    <div class="av-details" v-if="!isLoading">
      <AvVideoPlay :videoInfo="videoInfo" class="av-video" />
      <div class="video-info">
        <img :src="videoInfoImg" alt="" class="video-header-img">
        <div v-html="videoInfo.videoInfo"></div>
        <img :src="graphicImg" alt="" class="video-header-img">
        <Htmlcontent :html="videoInfo.graphic" class="html-content"></Htmlcontent>
        <div class="read-info">
          <div class="read-num">阅读 <span class="readnum">{{videoInfo.readCount|watchCount}}</span></div>
          <div class="zan" @click="focus"><span class="zan-btn" :class="{zanActive:videoInfo.isLike}"></span> {{videoInfo.likeCount|watchCount}}</div>
          <div class="zan" @click="collect"><span class="collect-btn" :class="{colActive:videoInfo.isFavorites}"></span> {{videoInfo.favoritesCount|watchCount}}</div>
        </div>
        <div class="avLogo">
          <img :src="avLogo" alt="">
        </div>

      </div>
    </div>
    <div class=" loading-box" v-else>
      <img class="loading" src="@/assets/gif/loading.gif">
    </div>
  </div>
</template>

<script>
import AvVideoPlay from "@/components/VueDplayer/AvVideoPlay.vue";
import {
  getAvDetail,
  collectAvCommentary,
  cancelCollectAvCommentary,
  likeAvCommentary,
  cancelLikeAvCommentary,
} from "@/api/on_demand.js";
import { Toast } from "vant";
export default {
  components: {
    AvVideoPlay,
    Htmlcontent: {
      props: {
        html: {
          type: String,
        },
      },
      render(h) {
        let html = this.html.replace(
          /<img.*?src=['"](.*?)['"]/g,
          `<ImgDecypt src="$1" style="margin:10px 0"`
        );
        let template = `<div>${html}</div>`;
        return h({ template: template });
      },
    },
  },
  data() {
    return {
      path: "",
      videoInfo: null,
      isLoading: true,
      videoInfoImg: require("@/assets/png/video_info_image.png"),
      graphicImg: require("@/assets/png/video_commentary_image.png"),
      avLogo: require("@/assets/png/avcommentary_bottom_logo.png"),
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      let req = {
        id: this.$route.query.id,
      };
      let ret = await this.$Api(getAvDetail, req);
      if (ret.code == 200) {
        this.isLoading = false;
        this.path = ret.data.sourceURL;
        this.videoInfo = ret.data;
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    async focus() {
      let req = {
        objId: this.videoInfo.id,
        type: "avcomment",
      };
      if (!this.videoInfo.isLike) {
        let ret = await this.$Api(likeAvCommentary, req);
        if (ret.code == 200) {
          //没点过赞 点击点赞
          this.videoInfo.isLike = true;
          this.videoInfo.likeCount++;
          Toast("点赞成功");
        }
      } else {
        let ret = await this.$Api(cancelLikeAvCommentary, req);
        if (ret.code == 200) {
          //已经点过赞 点击取消赞
          this.videoInfo.isLike = false;
          this.videoInfo.likeCount--;
          Toast("取消点赞");
        }
      }
    },
    async collect() {
      let req = {
        objId: this.videoInfo.id,
        type: "avcomment",
      };
      if (!this.videoInfo.isFavorites) {
        let ret = await this.$Api(collectAvCommentary, req);
        if (ret.code == 200) {
          //没收藏 收藏文章
          this.videoInfo.isFavorites = true;
          this.videoInfo.favoritesCount++;
          Toast("收藏成功");
        }
      } else {
        let ret = await this.$Api(cancelCollectAvCommentary, req);
        if (ret.code == 200) {
          //没收藏 收藏文章
          this.videoInfo.isFavorites = false;
          this.videoInfo.favoritesCount--;
          Toast("取消收藏");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.av-box {
  .leftArrow {
    font-size: 16px;
  }
  /deep/ .van-nav-bar__content {
    background: $vermillion;
    color: #fff;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
    font-size: 18px;
  }
  .av-details {
    height: 100%;
    .av-video {
      width: 100%;
      height: 211px;
    }
    .video-info {
      height: calc(100% - 257px);
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 0 10px;
      white-space: pre-line;
      font-size: 14px;
      .html-content {
        img {
          border-radius: 6px;
        }
      }
      .video-header-img {
        padding: 10px 0;
        width: 80px;
      }
      .read-info {
        margin: 20px auto 0;
        width: 300px;
        height: 20px;
        line-height: 20px;
        display: flex;
        justify-content: space-around;
        align-content: center;
        font-size: 16px;
        .readnum {
          color: #ff0000;
        }
        .zan-btn {
          display: inline-block;
          width: 18px;
          height: 18px;
          background: url("../../../../assets/png/dian_zhan_press.png");
          vertical-align: -2px;
        }
        .zanActive {
          background: url("../../../../assets/png/dian_zhan_pressed.png");
        }
        .collect-btn {
          display: inline-block;
          width: 18px;
          height: 18px;
          vertical-align: -2px;
          background: url("../../../../assets/png/dianzan_collect.png");
          background-size: 100%;
        }
        .colActive {
          background: url("../../../../assets/png/collected.png");
          background-size: 100%;
        }
      }
      .avLogo {
        text-align: center;
        margin: 20px auto;
        img {
          width: 120px;
        }
      }
    }
  }
  .loading-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    .loading {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
